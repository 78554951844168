<template>
  <div class="flex flex-col items-center w-full">
    <form
      v-if="!submitted"
      class="flex flex-col gap-4 justify-center w-full"
      @submit.prevent="onSubmit"
    >
      <PortollInput
        id="email"
        v-model="email"
        class="font-inter text-sm"
        icon="material-symbols:mail-rounded"
        autocomplete="off"
        placeholder="Email Address"
        :errors="error"
      />

      <PortollButton class="font-inter font-semibold bg-indigo-500">
        Get Started
      </PortollButton>
    </form>

    <form
      v-else
      class="flex flex-col gap-6 w-full"
      @submit.prevent="onVerify"
    >
      <PortollInput
        id="confirmation-code"
        v-model="verificationCode"
        type="text"
        autocomplete="off"
        placeholder="Confirmation Code"
        maxlength="6"
        tabindex="1"
        :errors="error.verification_code"
        icon="material-symbols:mail"
      />

      <div class="text-slate-700 text-sm">
        Didn't receive a code? <button
          class="text-blue-700 hover:underline focus:outline-none"
          type="button"
          tabindex="2"
          @click.prevent
        >
          Click here to resend
        </button>
      </div>

      <div class="flex items-center justify-between">
        <PortollButton
          class="font-bold w-full"
          kind="primary"
          tabindex="1"
        >
          Verify
        </PortollButton>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
  const emit = defineEmits();

  const email: Ref<string> = ref('');
  const error: Ref<Array<string>> = ref([]);
  const submitted: Ref<boolean> = ref(false);
  const verificationCode: Ref<string> = ref('');

  async function onSubmit() {
    try {
      await useApiFetch('auth/verify', {
        method: 'post',
        body: { email: email.value },
      });

      error.value = [];
      submitted.value = true;
    } catch (errors: any) {
      error.value = errors.email;
    }
  }

  async function onVerify() {
    try {
      await useApiFetch('auth/verify', {
        method: 'put',
        body: { verification_code: verificationCode.value },
      });

      emit('verified', { email: email.value });
    } catch (errors: any) {
      error.value = errors
    }
  }
</script>
