<template>
  <div class="bg-white rounded border shadow-xl shadow-slate-200 mx-8 mb-8 p-8">
    <div class="flex items-center justify-between gap-4 mb-5">
      <h2 class="font-inter font-bold text-3xl mb-3">
        Subscribe to Acme Inc.
      </h2>

      <div class="flex items-center gap-2 font-inter font-medium text-sm text-slate-500">
        Monthly

        <PortollToggle
          v-model="annual"
        />

        <span>
          Annually <span class="text-emerald-500">(-20%)</span>
        </span>
      </div>
    </div>

    <div v-if="!props.plans || !props.plans?.length">
      No plans available
    </div>

    <div v-if="!selectedPlan">
      <div class="grid grid-cols-3 gap-5">
        <SubscriptionPlanCard
          v-for="plan in props.plans"
          :plan="plan"
          @get-started="selectedPlan = plan"
        />
      </div>
    </div>

    <div
      v-else
      class="grid grid-cols-3 gap-5"
    >
      <div class="col-span-1">
        <SubscriptionPlanCard
          :plan="selectedPlan"
        />
      </div>

      <div class="bg-white border flex justify-center rounded shadow-lg shadow-slate-200/40 col-span-2 p-8">
        <div class="w-1/2 h-full flex flex-col justify-center items-center">
          <div class="mb-16 w-full">
            <PortollStepTracker
              :steps="3"
              :active="step"
            />
          </div>

          <PortollEmailVerificationForm
            v-if="step == 1"
            @verified="onVerified"
          />

          <PortollOnboardingRegisterForm
            v-if="step == 2"
            :plan="selectedPlan"
            :email="email"
            @registered="onRegistered"
          />

          <PortollUserSubscriptionForm
            v-if="step == 3"
            :stripe-client-secret="stripeClientSecret"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const props = defineProps<{
    plans: Array<SubscriptionPlan>|null;
  }>();

  const step = ref(1);
  const email = ref('');
  const annual = ref(false);
  const stripeClientSecret = ref('');
  const selectedPlan = ref<SubscriptionPlan | null>(null);

  function onVerified(data: any) {
    step.value = 2

    email.value = data.email;
  }

  function onRegistered(response: any) {
    step.value = 3;

    stripeClientSecret.value = response.stripe_client_secret;
  }
</script>
