<template>
  <form
    class="flex flex-col gap-4 items-center grow mx-16 mb-16 w-full"
    @submit.prevent="onSubmit"
  >
    <h2 class="font-inter font-bold text-slate-800">
      Payment Information
    </h2>

    <div
      id="payment-element"
      class="w-full"
    />

    <PortollButton class="flex items-center justify-center font-inter font-semibold relative w-full">
      Subscribe <Icon
        name="material-symbols:lock"
        size="16"
        class="absolute right-2"
      />
    </PortollButton>
  </form>
</template>

<script lang="ts" setup>
  import { loadStripe } from '@stripe/stripe-js';

  const props = defineProps<{
    stripeClientSecret: string;
  }>();

  const elements = ref<any>(null);

  const stripe: any = await loadStripe('pk_test_51OB1UEHIEhsPtLSVmIJnE8TMlHlHxLZrNhGkMBEqSGCTcQw4rUKRLerFjcumymRTv7JePwxUmhSAYBFtlMuQbsF500z4AX4qE2', {
    stripeAccount: 'acct_1OwVLeHCFSBVj825',
  });

  onMounted(async () => {
    elements.value = stripe.elements({
      clientSecret: props.stripeClientSecret,
    });

    const paymentElement = elements.value.create('payment', {
      layout: 'tabs',
    });

    paymentElement.mount('#payment-element');
  });

  async function onSubmit() {
    const { error }: any = await stripe.confirmPayment({
      elements: elements.value,
      confirmParams: {
        return_url: 'https://test-admin-three.portoll.net/home',
      },
    });
  }
</script>
