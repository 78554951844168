<template>
  <NuxtLayout name="portoll">
    <template #sidebarContent>
      <PortollSidebar />
    </template>

    <template #mainContent>
      <PortollHeaderBar v-if="auth.isLoggedIn" />

      <div class="bg-white border-b border-slate-200">
        <div class="flex items-center relative">
          <img
            src="/img/defaults/portoll-cover.png"
            alt="Default Portoll Cover"
            class="object-cover h-72 w-full"
          >

          <img
            src="/img/defaults/portoll-logo.png"
            alt="Default Portoll Logo"
            class="absolute -bottom-16 left-1/2 -translate-x-16 w-32"
          >
        </div>

        <div class="pt-20 pb-8">
          <h1 class="font-bold font-inter text-2xl text-center text-slate-950">
            {{ home?.name }}
          </h1>

          <p class="font-inter my-4 text-center text-slate-600">
            {{ home?.description }}
          </p>

          <div class="flex gap-8 justify-center items-center">
            <div class="flex gap-1 items-center">
              <Icon
                name="material-symbols:location-on-outline"
                class="h-5 w-5 text-slate-400"
              />

              <span class="font-inter font-medium text-slate-500 text-sm">Kentucky, US</span>
            </div>

            <div class="flex gap-1 items-center">
              <Icon
                name="material-symbols:link"
                class="h-6 w-6 -rotate-45 text-slate-400"
              />

              <a
                href="#"
                class="font-inter font-medium text-indigo-400 text-sm"
              >acmeinc.com</a>
            </div>

            <div class="flex items-center">
              <a href="#">
                <img src="/img/icons/facebook.svg">
              </a>
            </div>

            <div class="flex items-center">
              <a href="#">
                <img src="/img/icons/github.svg">
              </a>
            </div>

            <div class="flex items-center">
              <a href="#">
                <img src="/img/icons/x.svg">
              </a>
            </div>
          </div>
        </div>

        <div class="flex gap-3 justify-center">
          <div class="flex items-end">
            <NuxtLink
              to="/home"
              class="border-b-2 font-medium px-2 py-3"
              :class="{
                'border-transparent text-slate-500': $route.path != '/home' && $route.path != '/subscribe',
                'border-indigo-500 font-medium text-indigo-500': $route.path == '/home' || $route.path == '/subscribe',
              }"
            >
              Home
            </NuxtLink>
          </div>

          <div class="flex items-end">
            <NuxtLink
              to="/dashboard"
              class="border-b-2 flex gap-1 items-center font-medium px-2 py-3"
              :class="{
                'border-transparent text-slate-500': $route.path != '/dashboard',
                'border-indigo-500 font-medium text-indigo-500': $route.path == '/dashboard',
              }"
            >
              Dashboard

              <img
                src="/img/icons/protected-page.svg"
                class="h-4 w-4"
              >
            </NuxtLink>
          </div>

          <div class="flex items-end">
            <NuxtLink
              to="/"
              disabled
              class="border-b-2 flex gap-1 items-center font-medium px-2 py-3 "
              :class="{
                'border-transparent text-slate-400': $route.path != '/',
                'border-indigo-500 font-medium text-indigo-500': $route.path == '/',
              }"
            >
              <Icon
                name="material-symbols:add"
                class="h-4 w-4 text-slate-400"
              />

              Add Page
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-8 p-8">
        <div class="font-inter bg-white border border-slate-200 col-span-8 p-8 rounded shadow-lg shadow-slate-200">
          <h2 class="font-bold mb-4 text-3xl">
            Welcome to Acme Inc.!
          </h2>

          <div class="flex gap-8">
            <img
              src="/img/defaults/home-about-image.png"
              alt="Default About Image"
            >

            <div class="flex flex-col justify-around space-y-4">
              <div>
                <h4 class="font-bold text-slate-700">
                  Who We Are:
                </h4>

                <p class="text-slate-600">
                  Briefly introduce your business, your team, and your mission. Help clients understand who you are and what you stand for.
                </p>
              </div>

              <div>
                <h4 class="font-bold text-slate-700">
                  What We Offer:
                </h4>

                <p class="text-slate-600">
                  Describe the value and benefits clients will gain from subscribing to your Portoll. Whether it's exclusive content, vital documents, or expert advice, make it clear why they can't miss out.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="font-inter bg-white border border-slate-200 col-span-4 p-8 rounded shadow-lg shadow-slate-200">
          <h3 class="font-bold mb-4">
            Sign In
          </h3>

          <AuthLoginForm />

          <hr class="my-4">

          <p class="text-sm text-slate-600">
            Don't have an account yet? Subscribe below.
          </p>
        </div>
      </div>

      <div>
        <PortollWidgetSubscribe :plans="plans" />
      </div>
    </template>
  </NuxtLayout>
</template>

<script lang="ts" setup>
  import Pusher from 'pusher-js';
  import { loadStripe } from '@stripe/stripe-js';
  import { useAuthStore } from '~/stores/useAuthStore';

  const pusher = new Pusher('148dc9423c94a5afbe75', {
    cluster: 'us2'
  });

  const channel = pusher.subscribe('my-channel');
  channel.bind('my-event', function(data: any) {
    console.log(JSON.stringify(data));
  });

  if (window && window?.location?.search) {
    const stripe: any = await loadStripe('pk_test_51OB1UEHIEhsPtLSVmIJnE8TMlHlHxLZrNhGkMBEqSGCTcQw4rUKRLerFjcumymRTv7JePwxUmhSAYBFtlMuQbsF500z4AX4qE2', {
      stripeAccount: 'acct_1OwVLeHCFSBVj825',
    });

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    stripe.retrievePaymentIntent(clientSecret).then(async ({paymentIntent}) => {
      const response = await useApiFetch('/subscription', {
        method: 'POST',
        body: {
          payment_intent: paymentIntent,
        },
      });

      console.log(response);
    });
  }

  const auth = useAuthStore();

  const home: Ref<HomeResponse|null> = ref(null);
  const plans: Ref<Array<SubscriptionPlan>|null> = ref(null);

  home.value = await useApiFetch<HomeResponse>('home');

  const plansResponse = await useApiFetch<SubscriptionPlansResponse>('subscription-plans');
  plans.value = plansResponse?.data;

  useHead({
    title: 'eLink Portoll - Home'
  });
</script>
