<template>
  <h2
    v-if="!loading"
    class="font-bold mb-4 text-slate-800"
  >
    Personal Information
  </h2>

  <form
    v-if="!loading"
    class="flex flex-col gap-4 w-full"
    @submit.prevent="onSubmit"
  >
    <div class="flex gap-4 w-full">
      <div class="w-full">
        <PortollInput
          id="first_name"
          v-model="form.first_name"
          class="text-sm"
          icon="material-symbols:person"
          autocomplete="off"
          placeholder="First Name"
          :errors="errors.first_name"
        />
      </div>

      <div class="w-full">
        <PortollInput
          id="last_name"
          v-model="form.last_name"
          class="text-sm"
          icon="material-symbols:person"
          autocomplete="off"
          placeholder="Last Name"
          :errors="errors.last_name"
        />
      </div>
    </div>

    <div class="flex gap-4 w-full">
      <div class="w-full">
        <PortollInput
          id="phone"
          v-model="form.phone"
          v-maska
          class="text-sm"
          icon="material-symbols:call"
          autocomplete="off"
          placeholder="Phone"
          data-maska="(###) ###-####"
          :errors="errors.phone"
        />
      </div>
    </div>

    <div class="flex gap-4 w-full">
      <div class="w-full">
        <PortollInput
          id="password"
          v-model="form.password"
          class="text-sm"
          type="password"
          icon="material-symbols:lock"
          autocomplete="off"
          placeholder="Password"
          :errors="errors.password"
        />
      </div>

      <div class="w-full">
        <PortollInput
          id="password_confirmation"
          v-model="form.password_confirmation"
          class="text-sm"
          type="password"
          icon="material-symbols:lock"
          autocomplete="off"
          placeholder="Confirm Password"
          :errors="errors.password_confirmation"
        />
      </div>
    </div>

    <div class="my-2 select-none">
      <PortollCheckbox
        id="terms_and_privacy_agreement"
        v-model="form.terms_and_privacy_agreement"
        class="text-sm"
        :checked="form.terms_and_privacy_agreement"
        :errors="errors.terms_and_privacy_agreement"
      >
        I agree to the <a
          href="#"
          class="text-[#1c67a9]"
        >Terms</a> and <a
          href="#"
          class="text-[#1c67a9]"
        >Privacy</a> agreements.
      </PortollCheckbox>
    </div>

    <PortollButton
      class="font-semibold"
      kind="primary"
    >
      Continue
    </PortollButton>
  </form>

  <PortollLoadingIndicator v-else>
    Creating Your Account
  </PortollLoadingIndicator>
</template>

<script lang="ts" setup>
  import { useAuthStore } from '~/stores/useAuthStore';

  const emit = defineEmits(['registered']);
  const auth = useAuthStore();

  const props = defineProps({
    plan: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  });

  const form = ref({
    first_name: '',
    last_name: '',
    phone: '',
    password: '',
    password_confirmation: '',
    terms_and_privacy_agreement: false,
  });
  const errors = ref({});
  const loading = ref(false);

  async function onSubmit() {
    try {
      loading.value = true;

      const response = await useApiFetch('auth/onboarding/register', {
        method: 'post',
        body: {
          plan: props.plan,
          email: props.email,
          ...form.value,
        },
      });

      auth.fetchUser();

      emit('registered', response);
    } catch (error) {
      errors.value = error;

      loading.value = false;
    }
  }
</script>
